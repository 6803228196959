import PropTypes from "prop-types";
import React from "react";
import Helmet from "react-helmet";

import GMapsAlt from "../components/google-maps-alternative/GMapsAlt";
import i18nContext from "../components/i18n/i18n.context";
import Layout from "../components/layout/Layout";
import Page from "../components/page/Page";
import SEO from "../components/seo/Seo";
import { recaptchaKey } from "../conf/conf";

function GMapsAltPage({ location }) {
  return (
    <i18nContext.Provider value={{ locale: "fr" }}>
      <Layout>
        <SEO
          keywords={[
            "google maps",
            "map",
            "map api",
            "alternative google maps",
            "geocoding",
          ]}
          location={location}
          metaDescription="Vous recherchez une alternative à l'API Google Maps ? Jawg Maps propose des API puissantes pour les cartes dynamiques et statiques, le géocoding, la navigation et plus encore."
          title="Alternative à l'API Google Maps"
        />
        <Page>
          <Helmet>
            <script
              src={`https://www.google.com/recaptcha/api.js?render=${recaptchaKey}`}
            />
          </Helmet>
          <GMapsAlt />
        </Page>
      </Layout>
    </i18nContext.Provider>
  );
}

GMapsAltPage.propTypes = {
  location: PropTypes.object.isRequired, //eslint-disable-line react/forbid-prop-types
};

export default GMapsAltPage;
